const Index = () => (
  <div>
    <h2>LAMBDA</h2>
    <p>
      Browse the <a href='https://github.com/invisible-tech/invisible'>invisible github repo</a> for
      documentation on how to consume the API
    </p>
  </div>
)

export default Index
